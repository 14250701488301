import React from 'react'
import { graphql } from 'gatsby'

import './home.scss'
import Layout from 'layouts/Base'
import SEO from 'components/shared/SEO'

import DeliveryBanner from 'components/DeliveryBanner'
import { GuarenteeSection, BedroomSection, OutdoorSection, FloatingHeaderSection, RoomsSection, FeaturedItems } from 'components/HomePage'
import Spacer from 'components/shared/Spacer'
import EmailSignup from 'components/EmailSignup'

function IndexPage({data}) {
	return (
		<div>
      <FloatingHeaderSection />
      <Spacer/>
			<BedroomSection />
      <Spacer/>
			<RoomsSection />
      <FeaturedItems/> 
			<GuarenteeSection />
      <EmailSignup />
			<DeliveryBanner />
		</div>
	)
}

export default ({ data, location }) => {
  return (
    <>
      <SEO />
      <IndexPage data={data} />
    </>
  )
}

export const query = graphql`
  query {
    file(relativePath: { eq: "bg-large.png" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`